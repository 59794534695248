<template>
  <v-parallax
   height="100%"
    dark
    src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg"
  >
 <div class="cen">
         <h1 class="parallax display-2 font-weight-thin mb-3">Page not found</h1>
          <h1>404</h1>
          <v-btn class="mt-5 pink" large dark v-on:click="goback()">
            <v-icon size="20px">mdi-arrow-left</v-icon>
            <span>Back</span>
          </v-btn>   
 </div>
  </v-parallax>
</template>

<script>
export default {
  data: () => ({
    gradient: "to top right, rgba(63,81,181, .7), rgba(25,32,72, .7)"
  }),
  methods: {
    goback() {
      this.$router.go(-1);
    }
  }
};
</script>
<style scoped>
.cen{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 800px;
}
.pink{
    background-color: #a229a6 !important;
}
</style>